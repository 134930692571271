<template>
  <v-layout column>
      <v-form v-show="!submitted">
    <v-card-title>
      COVID-19 Symptoms
    </v-card-title>
    <v-card-text style="font-size: 18px ">
People with COVID-19 have had a wide range of symptoms reported – ranging from mild symptoms to severe illness. 
Symptoms may appear 2-14 days after exposure to the virus. Check the boxes for each symptom you've experienced in the last 24 hours:        
<v-container fluid>
      <v-checkbox left v-model="selected" label="Fever or chills" value="Fever or chills"></v-checkbox>
      <v-checkbox v-model="selected" label="Cough" value="Cough"></v-checkbox>
      <v-checkbox v-model="selected" label="Shortness of breath or difficulty breathing" value="Shortness of breath or difficulty breathing"></v-checkbox>
      <v-checkbox v-model="selected" label="Fatigue" value="Fatigue"></v-checkbox>
      <v-checkbox v-model="selected" label="Muscle or body aches" value="Muscle or body aches"></v-checkbox>
      <v-checkbox v-model="selected" label="Headache" value="Headache"></v-checkbox>
      <v-checkbox v-model="selected" label="New loss of taste or smell" value="New loss of taste or smell"></v-checkbox>
      <v-checkbox v-model="selected" label="Sore throat" value="Sore throat"></v-checkbox>
      <v-checkbox v-model="selected" label="Congestion or runny nose" value="Congestion or runny nose"></v-checkbox>
      <v-checkbox v-model="selected" label="Nausea or vomiting" value="Nausea or vomiting"></v-checkbox>
      <v-checkbox v-model="selected" label="Diarrhea" value="Diarrhea"></v-checkbox>
    </v-container>
      </v-card-text>
      
      <v-card-actions>
        <v-spacer></v-spacer><v-btn @click="submitted=true" color="primary">Submit</v-btn>
      </v-card-actions>
        </v-form>
        <v-card-text class="mb-5 pt-5" v-show="submitted" style="font-size: 18px;">
        <span v-if="selected.length > 0">Your response has been recorded. Most people with COVID-19 have mild illness and can recover at home without medical care. Do not leave your home, except to get medical care. Do not visit public areas. Be sure to get care if you have trouble breathing, or have any other emergency warning signs, or if you think it is an emergency.</span> 
<span v-else style=" text-align: center">Your response has been recorded. Thank you!</span>
      </v-card-text>
  </v-layout>
</template>

<script>
export default {
data() {
    return {
      selected: ['John'],
    submitted:false,
    }
}
}
</script>

<style>

</style>