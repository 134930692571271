<template>
  <v-layout column>
      <v-form v-show="!submitted">
    <v-card-title>
      Numeric Pain Rating Scale
    </v-card-title>
    <v-card-text style="font-size: 18px ">
      Please rate your pain in the last 24 hours from 0 - 10, with 0 as No Pain and 10 as Unbearable. Your response will be recorded and included in your chart for review by your medical provider.
        <v-slider
        class="mt-5 pt-5"
          v-model="value"
          step="1"
          ticks
          thumb-label="always"
          :thumb-size="30"
          max=10
          :tick-labels="painRatings"
        ></v-slider>
      </v-card-text>
      
      <v-card-actions>
        <v-spacer></v-spacer><v-btn @click="submitted=true" color="primary">Submit</v-btn>
      </v-card-actions>
        </v-form>
        <v-card-text class="mb-5 pt-5" v-show="submitted" style="font-size: 18px; text-align: center">
Your response has been recorded. Thank you!
      </v-card-text>
  </v-layout>
</template>

<script>
export default {
data() {
    return {
      painRatings: [
      'No Pain',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'Unbearable'
    ],
    submitted:false,
    }
}
}
</script>

<style>

</style>