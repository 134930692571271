<template>
  <v-layout class="patient-page" justify-center>
    <v-card
      outlined
      style="max-width: 800px; width: 100%;"
      class="mt-2"
      :loading="submissionLoading"
      flat
    >
    <v-layout row justify-center class="pb-3">
          <img
          v-if="appTheme"
            class="mx-5 px-5 pt-5 mt-5"
            style="width: 60%; max-height: 130px; object-fit: contain;"
            :src="appTheme.login"
          />
        </v-layout>
        <PatientReportedPainScale v-if="showPain"/>
        <PatientReportedCovid v-if="showCovid"/>
      <div
        style="position: absolute; bottom:-25px; right: 3px; opacity:0.5; font-size:12px;"
      >
        Powered by NovoRPM
      </div>
      
    </v-card>
  </v-layout>
</template>

<script>
// @ is an alias to /src
import PatientReportedPainScale from '@/components/PatientReportedPainScale'
import PatientReportedCovid from '@/components/PatientReportedCovid'
import { mapActions, mapState } from 'vuex'
import bus from '@/core/helpers/bus'
import HelperMixin from '@/core/mixins/HelperMixin'
import consts from '@/consts'

export default {
  mixins: [HelperMixin],
  name: 'loginpage',
  components: {PatientReportedPainScale, PatientReportedCovid},
  data() {
    return {
      showPain: false,
      showCovid: false
    }
  },
  computed: {
    ...mapState('auth', ['user', 'appTitle', 'subdomain', 'loginURL', 'appTheme']),
    lockEmailInput() {
      if (this.userGroupInviteToken || this.patientShareToken) {
        return true
      }
      return false
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false
    },
    tagline() {
      return 'Powered by OneChart Health'
    },
    version() {
      return consts.version
    }
  },
  methods: {
    ...mapActions('auth', ['signIn', 'signOut']),
    ...mapActions('provider', ['getProviderAuth']),
    parseURLParameters() {
      const search = this.$route.query
      if (search.pain) {
        this.showPain = true
      }

      if (search.covid) {
        this.showCovid = true
      }

      if (search.eventType === 'MEASUREMENT_COVID_RISK') {
        this.showCovid = true
      }
    },
    switchToRegister() {
      this.$router.push({ name: 'Register', query: this.$route.query })
    },
    resetPassword() {
      this.$router.push({ name: 'PasswordReset'})
    },
    submitLogin() {
      this.loginLoading = true
      if (this.$refs.loginForm.validate()) {
        let tokenHere = null
        if (this.patientShareToken) {
          tokenHere = this.patientShareToken
        } else if (this.userGroupInviteToken) {
          tokenHere = this.userGroupInviteToken
        }
        let postActionsPayload = { shareID: this.shareID, authToken: tokenHere, provider: this.provider, affiliateCode: this.affiliateCode }
        const credentials = {
          email: this.email,
          password: this.password,
          payload: postActionsPayload
        }
        this.signIn(credentials)
          .then(() => {
            // bus.$emit('toast', { type: 'success', text: 'Logged In' })
            console.log('signed in')
          })
          .catch(error => {
            console.log(error)
            switch (error.code) {
              case 'auth/user-not-found':
                bus.$emit('toast', {
                  type: 'error',
                  text: 'User not found. Sign up instead?'
                })
                break
              case 'auth/invalid-email':
                bus.$emit('toast', { type: 'error', text: 'Invalid Email' })
                break
              case 'auth/wrong-password':
                bus.$emit('toast', { type: 'error', text: 'Invalid password' })
                break
              default:
                bus.$emit('toast', { type: 'error', text: error.code })
            }
            this.loginLoading = false
          })
      } else {
        this.loginLoading = false
      }
    }
  },
  mounted() {
    this.signOut()
    this.parseURLParameters()
  }
}
</script>
